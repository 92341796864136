import React, { useEffect, useState, useContext } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "../../../../assets/styles/slideshow.css"
import {
  TopHead,
  Container,
  ButtonTransp,
  Heading
} from "../../../../components/styled/StyledComponents";
import backButton from "../../../../assets/images/back.png";

import gin1 from "../../../../assets/images/process/ginning/1.jpg";
import gin2 from "../../../../assets/images/process/ginning/2.jpg";
import gin3 from "../../../../assets/images/process/ginning/3.jpg";

import spin1 from "../../../../assets/images/process/spinning/1.jpg";
import spin2 from "../../../../assets/images/process/spinning/2.jpg";
import spin3 from "../../../../assets/images/process/spinning/3.jpg";


import knitw1 from "../../../../assets/images/process/knitweave/1.jpg";
import knitw2 from "../../../../assets/images/process/knitweave/2.jpg";

import dye1 from "../../../../assets/images/process/dyeing/1.jpg";
import dye2 from "../../../../assets/images/process/dyeing/2.jpg";

import TraceContext from "../../../../contexts/traceContext";
import ProcessLocationOnMap from "./processLocationOnMap";
import { scrollToTop } from "../../../../functions/utils";

const sliderConfig = {
  arrows: false,
  indicators: i => <div className="indicator">{/* {i + 1} */}</div>
};

const Slideshow = ({ imgs }) => {
  return (
    <Slide className="with__gradient" easing="ease" {...sliderConfig}>
      {imgs.map((pic, i) => (
        <div key={i} className="each-slide">
          <div style={{ backgroundImage: `url(${pic})` }}></div>
        </div>
      ))}
    </Slide>
  );
};

// Possible processes:
// ["ginning", "spinning", "knitting & weaving", "dyeing"]



const Process = ({ name, selected, hideButton }) => {

  const [nameAndLocation, setNameAndLocation] = useState(selected?.location)

  console.log(nameAndLocation, "name and location")

  const _mapping = {
    ginning: {
      imgs: [gin3, gin1, gin2], // the first element will appear as background image
      name: "Ginning Process",
      type: "ginning",
      text:
        `Your raw cotton is filled with cotton seeds, impurities from field, which needs to be separated for top-quality products. This begins by first converting raw cotton into cotton bales. This t-shirt comes from raw cotton which was processed at ${nameAndLocation?.name}`
    },
    spinning: {
      imgs: [spin1, spin2, spin3],
      name: "Spinning Process",
      type: "spinning",
      text:
        `Cotton bales need to undergo Spinning process for providing yarn. Our partnerfor this process is ${nameAndLocation?.name} who have also founded their initiative with a view to create sustainable business along with social impact focused on fair equity.`
    },
    "knitting & weaving": {
      imgs: [knitw1, knitw2],
      name: "Knitting & Dyeing Process",
      type: "knitting",
      text:
        "Yarn needs to be further processed into quality fabric which can withstand daily wear and tear while also providing a rich texture. It happens at this stage."
    },
    dyeing: {
      imgs: [dye1, dye2],
      name: "Dyeing Process",
      type: "dyeing",
      text:
        `The charm and beauty of a fabric is governed largely by the quality of its colours, and it's also important to pay attention to our dyeing methods and water usage practices. It's on these very considerations that our farmer-led initiative has partnered with ${nameAndLocation?.name}`
    }
  };

  

  const [currentProcess, setCurrentProcess] = useState(_mapping[selected?.name])
 

 

  const { costing: { processing } } = useContext(TraceContext)



  useEffect(() => {
    if (!currentProcess) hideButton();
    scrollToTop()
  }, []);

  useEffect(() => {
    setNameAndLocation(processing?.data?.find(el => el.name.includes(currentProcess?.type))?.location?.address)
  }, [currentProcess])

  return (
    <div
      style={{
        maxWidth: 500,
        position: "relative",
        margin: "auto"
      }}
    >
      <ButtonTransp
        color="white"
        style={{
          position: "fixed",
          top: 5,
          left: 5,
          zIndex: 3,
          padding: 10
        }}
        onClick={hideButton}
      >
          <i
            
            >
              <img style={{width: "1.5rem", height:"1.5rem", backgroundColor:"white", padding:"0.2rem", borderRadius:"50%"}} src={backButton}/>
            </i>
      </ButtonTransp>
      <Container>
        <TopHead
          bgImg={currentProcess?.imgs[0]}
          style={{
            height: 170,
            backgroundPosition: "bottom"
          }}
        />
      </Container>
      <Heading className="bold">{currentProcess?.name}</Heading>
      <div style={{ margin: "0 20px 20px" }}>
        <div
          className="white__box box__text__border"
        >
          <div className="bold" style={{ paddingBottom: 5 }}>{nameAndLocation?.rawAddress}</div>
          <div style={{ fontSize: 13 }}>{currentProcess?.text}</div>
        </div>
      </div>

      <div style={{ fontSize: 20, fontWeight: "bold", margin: "0 20px 20px" }}>
      </div>
      {currentProcess?.imgs?.length > 0 && <Slideshow imgs={currentProcess?.imgs} />}


      {/* <section className="process__select">
        {processing?.data?.map(el => <button className="process__select__btn" onClick={() => setCurrentProcess(_mapping[el?.name])}>{el?.name}</button>)}
      </section> */}

      <ProcessLocationOnMap locationArray={processing?.data}
        changeProcess={_processName => setCurrentProcess(_mapping[_processName])}
        locationObject={nameAndLocation} />
    </div>
  );
};

export default Process;
