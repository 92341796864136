import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Analyse from "../screens/analyse/Analyse";
import ReactGA from "react-ga";
import CottonTraceInitiator from "../screens/cottonTrace";

ReactGA.initialize("UA-207381738-1");

const Routing = (props) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      <Redirect from="/trace/:id" to="/cotton/:id" />

      <Route
        exact
        path="/"
        component={() => {
          window.location = "https://ru.world";
        }}
      />
      <Route exact path="/analyse/:encodedId" component={Analyse} />
      <Route exact path="/cotton/:id" component={CottonTraceInitiator} />

    </Switch>
  );
};

export default Routing;
