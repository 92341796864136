import styled from "styled-components";

import alpsBg from "../../assets/images/alpsBg.jpg";

export const TopHead = styled.div`
  height: 150px;
  width: 100%;
  background: no-repeat center;
  /* background-image: url("https://anywhereweroam.com/wp-content/uploads/2019/12/italian-alps-8.jpg"); */
  background-image: ${props => `url(${props.bgImg || alpsBg})`};
  background-size: cover;
  border-radius: 0 0 25px 25px;
  position: relative;
`;


export const ButtonTransp = styled.button`
  background: none;
  border: none;
  color: ${props => props.color || "#0e4554"};
  padding: 0;
`;

export const Container = styled.div`
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
`;

export const TimelineContainer = styled.div`
  max-width: 500px;
  position: relative;
  margin: auto;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 30px; */

  &::after {
    content: "";
    position: absolute;
    width: 2px;
    /* background-color: #d8d8d8; */
    top: 10px;
    bottom: 0;
    left: 25%;
    margin-left: -3px;
    z-index: 2;

    /* background-image: linear-gradient(#bf6060 33%, rgba(255, 255, 255, 0) 0%); */
    background-image: linear-gradient(#c7c7c7 75%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 2px 13px;
    background-repeat: repeat-y;
  }
`;

export const HubRow = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 20px 0 10px;
`;

export const FarmersArtisans = styled.div`
  background-color: ${props => props.color || "#a4c36d"};
  align-self: flex-end;
  font-weight: bold;
  color: white;
  display: flex;
  z-index: 3;
  border-top-left-radius: 25px;

  width: 80%;
`;

export const LeftPriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  width: 20%;
`;

export const LeftPrice = styled.div`
  width: 20%;
  color: white;
  font-weight: bold;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  max-height: 3rem;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 470px) {
    padding-right: 10px;
  }
`;

export const LeftPriceInnerBox = styled.div`
  background-color: ${props => props.color || "#90af5a"};
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1.1px 5.3px 4px rgb(0 0 0 / 3%), 0 3.6px 17.9px rgb(0 0 0 / 4%),
    0 16px 80px rgb(0 0 0 / 7%);
  display: flex;
  align-items: center;
  min-width: 2.5rem;
  justify-content: flex-end;
  font-weight: normal;
  width: 100%;
  font-family: auto;

  /* font-size: 0.8em; */
`;

export const RightCheckpoint = styled.div`
  /* width: 70%; */
  width: 95%;
  text-align: left;
  padding-left: 5px;
  padding: 15px;
  color: ${props => props.textColor || "white"};
  background-color: ${props => props.color || "#90af5a"};
  border-radius: 10px;
  /* border-top-left-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
  box-sizing: border-box;
`;

export const Dot = styled.div`
  position: absolute;
  left: calc(25% - 7.5px);
  height: 10px;
  width: 10px;
  top: 20px;
  background-color: #a4c36d;
  border-radius: 50%;
  z-index: 3;
`;

export const RightRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
`;

export const Subtitle = styled.div`
  font-size: 13px;
`;

export const HubCheckpoints = styled.div`
  display: flex;
  justify-content: flex-start;
  /* width: 75%; */
  align-items: center;
  margin-bottom: 10px;
`;

export const CheckpointPrice = styled.div`
  padding: 5px;
  background-color: ${props => props.color || "#bcdee8"};
  border-radius: 5px;
  color: ${props => props.textColor || "#64696b"};
  width: 40px;
  font-family: none;
  text-align: right;
  font-size: 0.8em;
`;

export const Heading = styled.div`
  padding: 30px;
  // background-color: #adc486;
  position: relative;
  bottom: 3rem;
  margin: 0 25px -25px;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;

  // background-color: rgb(221 226 227 / 46%);
  background-color: #ffffffa3;
  backdrop-filter: blur(22px);
  // color: black;
  color: #014d63;
  border: 2px solid #014d6330;
  border-left: none;
  border-right: none;
`;

export const FarmerCircle = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  /* background: black; */
  margin: auto;
`;

export const SocialMediaButton = styled.button`
  border: none;
  outline: none;
  height: 30px;
  width: 30px;
  background-image: ${props => `url(${props.img})` || ""};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 3;
  background-color: transparent;
  cursor: pointer;
`;
