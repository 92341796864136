export const sortByPic = (a, b) => {
    if (a.picture && !b.picture) return -1;
    else if (!a.picture && b.picture) return 1;
    else return 0;
};


export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

export const capitalizeFirstLetter = (string) => {
    return string.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
}