import { useEffect } from "react";
import axios from "axios";

const Analyse = props => {
  const encodedId = props.match?.params?.encodedId;

  useEffect(() => {
    console.log("Mounted");
    expandUrl();
  }, []);

  const expandUrl = async () => {
    try {
      const { data } = await axios.get(
        "https://sfsgil.agrotrust.io/tinyurl/" + encodedId
      );
      const newUrl = data.longUrl.split("https://grassroots.agrotrust.io")[1];
      console.log(newUrl);
      props.history.replace(newUrl);
    } catch (error) {
      window.alert(error.message);
      console.log(error.message);
    }
  };

  return <div>Decoding trace, please wait...</div>;
};

export default Analyse;
