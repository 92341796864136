import React, { useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { scrollToTop } from '../../../../functions/utils';
import googleMapsHack from './googleMapsHack';

googleMapsHack()

const mapStyles = [
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a7a7a7"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#737373"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#767676"
            }
        ]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#dadada"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#8c8c8c"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#696969"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#b3b3b3"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d6d6d6"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "weight": 1.8
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d7d7d7"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "color": "#808080"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f9d3bf"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]

const CustomMarker = ({ name, type, number, zoomLevel, changeProcess }) => {

    const [showType, setShowType] = useState(false)

    return <div
        onMouseEnter={() => setShowType(true)}
        onMouseLeave={() => setShowType(false)}
        onClick={() => {
            changeProcess(type)
            scrollToTop()
        }} className={`map__marker ${number} ${zoomLevel <= 3 ? "small" : ""}`}>{name}
        {showType && ` (${type})`}
    </div>;

}
const ProcessLocationOnMap = ({ locationObject, locationArray = [], changeProcess }) => {
    // console.log(locationArray, 298)
    const [lat, long] = locationObject?.location?.coordinates || []
    const [zoomLevel, setZoomLevel] = useState(6)

    const mapRef = useRef();

    return (<>
        <div className="bold" style={{ textAlign: "center", marginBottom: 10 }}>See where it happened</div>
        <div className="map__container">
            <GoogleMapReact
                ref={mapRef}
                bootstrapURLKeys={{ 
                    key: "AIzaSyAJcdY3ycD6vpKUJgV9nC_Vef8OWgN7Gfg" 
                    // key: "AIzaSyDIJ9XX2ZvRKCJcFRrl-lRanEtFUow4piM"
                }}
                defaultCenter={[17.2946401, 74.445585]}
                defaultZoom={6}
                options={{
                    styles: mapStyles
                }}
                resetBoundsOnResize={true}
                onChange={({ center, zoom, bounds, marginBounds }) => {
                    setZoomLevel(zoom)
                }
                }
            >
                {locationArray?.map(({ name, location }, index) => <CustomMarker
                    lat={location?.address?.location?.coordinates[0]}
                    lng={location?.address?.location?.coordinates[1]}
                    name={location?.name}
                    type={name}
                    key={index}
                    number={"_" + (index % 4).toString()}
                    zoomLevel={zoomLevel}
                    changeProcess={changeProcess}
                />)}

            </GoogleMapReact>
        </div>
    </>)
}


export default ProcessLocationOnMap;