// Components / Hooks from libraries 
import { useState, useContext } from "react"
// import axios from "axios" //for making HTTP requests
// import ReactGA from "react-ga"; //for Google Analytics
import { useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";


// Styles
// import "../../styles/cottonTrace.scss"
import "../../assets/styles/cottonTrace.scss"

import opacityTransitionPreset from "../../constants/opacityTransitionPreset"

// Image assets
import sahyadriFarmsLogo from "../../assets/images/sahyadriFarmsLogo.png"
import ruLogo from "../../assets/images/ruLogo.png"

// Context
import TraceContext from "../../contexts/traceContext";

// Util functions
import calculatePercent from "../../utils/calculatePercent";
import useHashRouteToggle from "../../hooks/useHashRouteToggle";

import AbstractionFarmerArtisan from "./detailed/artisans/AbstractionFarmerArtisan";
import Process from "./detailed/process/processView"
import ModalButtons from "./modalButtons";
import SelfieSection from "./selfieSection/selfieSection";

/**
 * Static lists helpful in map() rendering
 */


/**
 * CottonTrace is the first main (parent) component that'll render the useful trace information
 * It will have relevant child components as well.
 */
const CottonTrace = () => {

    const [showFarmer, setShowFarmer] = useHashRouteToggle("#farmers");
    const [showArtisan, setShowArtisan] = useHashRouteToggle("#artisans");
    const [showProcess, setShowProcess] = useHashRouteToggle("#process");
    const [selectedProcess, setSelectedProcess] = useState(null);

    // console.log(useContext(TraceContext), 118)

    const { mrp, costing: { harvest, farmerEarning, processing, postProcessing }, consumerName, consumerImage } = useContext(TraceContext)

    let modelledDataCost = {

        // for apparel making
        accessories: null,
        utility: null,
        financing: null,
        designing: null,
        marketing: null,
        operatingMargins: null,
        labor: null,

        // for processing
        ginning: null,
        spinning: null,
        knittingWeaving: null,
        dyeing: null
    }

    let modelledLocation = {
        ginning: "",
        spinning: "",
        knittingWeaving: "",
        dyeing: ""
    }

    processing?.data?.forEach(({ name, cost, location }) => {
        if (name.includes("ginning")) {
            modelledDataCost.ginning = cost;
            modelledLocation.ginning = location?.address
        }
        else if (name.includes("spinning")) {
            modelledDataCost.spinning = cost;
            modelledLocation.spinning = location?.address
        }
        else if (name.includes("knitting")) {
            modelledDataCost.knittingWeaving = cost;
            modelledLocation.knittingWeaving = location?.address
        }
        else if (name.includes("dyeing")) {
            modelledDataCost.dyeing = cost;
            modelledLocation.dyeing = location?.address
        }
    })

    // implementing a forEach() for future, otherwise find() is enough
    postProcessing?.data?.forEach(({ name, cost }) => {
        if (name.includes("overheads")) modelledDataCost.utility = cost;
        else if (name.includes("extra")) modelledDataCost.accessories = cost;
        else if (name.includes("labor")) modelledDataCost.labor = cost;
        else if (name.includes("financing")) modelledDataCost.financing = cost;
        else if (name.includes("design")) modelledDataCost.designing = cost;
        else if (name.includes("marketing")) modelledDataCost.marketing = cost;
        else if (name.includes("operating")) modelledDataCost.operatingMargins = cost;
    })

    const postProcessingCostSansArtisans = postProcessing?.cost - modelledDataCost?.labor

    const TRACE_PROCESS = [
        {
            amount: mrp,
            name: "Retail Store",
            description: <>
                The Finished Product<br />
                <small style={{ color: "grey" }}>(Discounts, if any, are borne by the retail store)</small>
            </>,
            id: "retail__store",
        },
        {
            amount: postProcessing?.cost ? postProcessing?.cost - modelledDataCost.labor : null,
            name: "Apparel Making",
            description: "How your apparel was crafted",
            id: "apparel__making",
            expendable: true
        },
        {
            amount: modelledDataCost.labor,
            name: "Artisans",
            description: "Sahyadri Farms Sustainable Grassroots Initiatives Limited",
            id: "artisans",
            showInAnotherScreen: true,
            screenHashFn: setShowArtisan
        },
        {
            amount: processing?.cost,
            name: "Cotton Processing",
            description: "The Processing Stage",
            id: "cotton__processing",
            expendable: true,
            screenHashFn: setShowProcess,
        },
        {
            amount: harvest,
            name: "Harvest ",
            description: "The farmers that harvested this cotton",
            id: "harvest",
            showInAnotherScreen: true,
            screenHashFn: setShowFarmer
        }
    ]

    const { accessories, utility, financing, designing, marketing, operatingMargins, labor, ginning, spinning, knittingWeaving, dyeing } = modelledDataCost

    const APPAREL_MAKING_LIST = [
        {
            name: "Accessories",
            percent: calculatePercent(accessories, postProcessingCostSansArtisans)
        },
        {
            name: "Utility Charges",
            percent: calculatePercent(utility, postProcessingCostSansArtisans)
        },
        {
            name: "Financing",
            percent: calculatePercent(financing, postProcessingCostSansArtisans)
        },
        {
            name: "Designing",
            percent: calculatePercent(designing, postProcessingCostSansArtisans)
        },
        {
            name: "Marketing",
            percent: calculatePercent(marketing, postProcessingCostSansArtisans)
        },
        {
            name: "Operating Margins",
            percent: calculatePercent(operatingMargins, postProcessingCostSansArtisans)
        }
    ]

    const totalProcessingCost = processing?.cost

    const PROCESSING_LIST = [
        {
            name: "Ginning",
            location: modelledLocation.ginning,
            percent: calculatePercent(ginning, totalProcessingCost),
            showInAnotherScreen: true,
        },
        {
            name: "Spinning",
            location: modelledLocation.spinning,
            percent: calculatePercent(spinning, totalProcessingCost),
            showInAnotherScreen: true,
        },
        {
            name: "Knitting & Weaving",
            location: modelledLocation.knittingWeaving,
            percent: calculatePercent(knittingWeaving, totalProcessingCost),
            showInAnotherScreen: true,
        },
        {
            name: "Dyeing",
            location: modelledLocation.dyeing,
            percent: calculatePercent(dyeing, totalProcessingCost),
            showInAnotherScreen: true,
        },

    ]

    if (showFarmer) return <AbstractionFarmerArtisan name="farmers" hideButton={() => setShowFarmer(false)}
    />
    if (showArtisan) return <AbstractionFarmerArtisan name="artisans" hideButton={() => setShowArtisan(false)} />
    if (showProcess) return <Process name="process" selected={selectedProcess} hideButton={() => setShowProcess(false)} />

    return <section id="trace__parent" style={{ paddingBottom: 30 }}>
        <div id="trace__header">
            <div id="logo__container">
                <img className="img__logo" alt="Sahyadri Farms Logo" src={sahyadriFarmsLogo} />
                <img className="img__logo" alt="RU Logo" src={ruLogo} />
            </div>
            <div style={{ padding: "0 30px", marginLeft: 30 }}>
                <h3 className="bold" style={{ marginBottom: 10, color: "#6A0E0E" }}>
                    Hello RU Customer,
                </h3>
                <div className="blue">
                    Thank you for buying this product from a Farmer-owned brand <span style={{
                        fontVariationSettings: "'wght' 800"
                    }}>'RU'</span> by Sahyadri Farms Sustainable Grassroots Initiative
                </div>
            </div>
        </div>

        <ModalButtons />

        <FarmerEarning
            // costing={harvest}
            costing={farmerEarning} />


        <section
            className="white__trace__container"
            id="white__trace__container"
            style={{ color: "#014D63" }}
        >

            <SelfieSection consumerImage={consumerImage} consumerName={consumerName} />

            {TRACE_PROCESS.map((el, i) => <TraceElement
                expandData={el.expendable ? el.id === "apparel__making" ? APPAREL_MAKING_LIST : PROCESSING_LIST : []}
                {...el}
                setSelectedProcess={setSelectedProcess}
                key={i} />)}

            {/* <SelfieSection consumerImage={consumerImage} consumerName={consumerName} /> */}

        </section>
        <a href="https://ru.world/pages/who-we-are" rel="noreferrer" target="_blank" style={{ textAlign: "center", textDecoration: "underline", display: "block" }}>
            Click here to know more about us
        </a>
        <div></div>
    </section>
}


/**
 * TraceElement is a component representing a checkpoint in the trace.
 * The checkpoint can contain information that'll be visible on 'expanding' the info
 * @param {Object} contains amount, name of the checkpoint, description of the checkpoint 
 */

const TraceElement = ({ amount, name, description, id, ...rest }) => {

    const [isExpanded, setIsExpanded] = useState(false)

    const expandOrNavigate = () => {
        if (rest.expendable) setIsExpanded(!isExpanded)
        else if (rest.showInAnotherScreen) rest.screenHashFn(true)
        else return;
    }

    const expandTransition = useTransition(isExpanded, {
        from: {
            opacity: 0
        },
        enter: {
            opacity: 1
        }
    })


    return <div onClick={expandOrNavigate} className="white__box" style={{ borderBottom: rest.expendable ? '2px solid #feb29c' : 'none' }} id={id}>
        <div className="main__details">
            <div className="amount">{![undefined, null].includes(amount) ? '₹' + amount?.toFixed(1) : null}
            </div>
            <div className="checkpoint">
                <div className="name">{name}
                    {rest.expendable && <div className={`arrow__icon ${isExpanded ? "up" : ""}`} />}
                    {rest.showInAnotherScreen && <div className="arrow__icon right" />}
                </div>
                <div className="description">{description}</div>
            </div>
        </div>

        {
            expandTransition((style, item) => item ? <animated.div style={style} className="expanded__details" id="expanded__details">
                {
                    rest.expandData.map(({ name, location, percent, showInAnotherScreen, ..._rest }) => <div className="row__container" onClick={e => {
                        if (showInAnotherScreen) {
                            rest.setSelectedProcess({
                                name: name.toLowerCase(),
                                location
                            })
                            rest.screenHashFn(true)
                        }
                        e.stopPropagation()
                    }} key={name}>
                        {
                            !isNaN(percent) && <div className="percent">{percent}%</div>
                        }

                        <div style={{ paddingLeft: 10, paddingRight: 30, flex: 1 }}>
                            <div className="item__name">{name}</div>
                            <div className="subscript">{location?.rawAddress}</div>
                        </div>

                        {showInAnotherScreen &&
                            <div className="arrow__icon right" style={{
                                alignSelf: "center"
                            }}
                            ></div>}
                    </div>)
                }
            </animated.div> : null)
        }

    </div>
}


/**
 * FarmerEarning is a component which is rendered before the Trace Checkpoints.
 * Shows consolidated information on how much the farmers earned, and its break-up as well on expansion.
 */
const FarmerEarning = ({ costing = { total: null, contribution: null, firm: null } }) => {

    const { total, contribution, firm, profitShare } = costing || null

    const [isExpanded, setIsExpanded] = useState(false)

    const showValue = ![undefined, null].includes(costing)

    return <div className="white__section" style={{ color: "#014D63" }}>
        <div className="farmer__icon__container"
            onClick={() => total ? setIsExpanded(true) : null} style={{
                borderBottom: !isExpanded && total ? "3px solid" : "none"
            }}
        >
            <div id="farmer__icon"></div>
            {total && <div>
                <div style={{ fontSize: 12, paddingTop: 5 }}>Total Farmers Earning</div>
                <div
                    className="no__font bold"
                    style={{ fontSize: 22, fontWeight: "bold" }}
                >
                    {showValue && `₹${total?.toFixed(1)}`}

                </div>
            </div>}

        </div>

        {
            (isExpanded && showValue) ? <div className="white__box" id="farmer__earnings">
                <div style={{
                    textAlign: "center",
                    background: "aliceblue",
                    paddingBottom: 10,
                    borderRadius: "inherit"

                }}>
                    <div style={{ paddingTop: 10 }}>
                        <div
                            className="no__font bold"
                            style={{ fontSize: 22, fontWeight: "bold" }}
                        >
                            ₹{firm?.toFixed(1)}
                        </div>
                        <div>Farmers fair and firm earning</div>
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        <div
                            className="no__font bold"
                            style={{ fontSize: 22, fontWeight: "bold" }}
                        >
                            ₹{contribution?.toFixed(1)}
                        </div>
                        <div>Your contribution as an additional earning for the farmer</div>
                    </div>
                </div>
                <p style={{ fontSize: 12 }}>
                    We ensure minimum support price as declared by local government while
                    purchasing cotton from our farmers.<br />

                    We will be sharing <span className="no__font" style={{ fontSize: "1.3em", fontWeight: "bold" }}>
                        {profitShare || 0}%&nbsp;
                    </span>
                    of
                    our
                    overall estimated profit with our farmers / Farmer Producing Companies
                    (FPCs) from whom we source our cotton, respectively.
                </p>
                <button
                    className="hide__btn"
                    onClick={() => setIsExpanded(false)}
                >
                    Hide
                </button>
            </div> : <div
                style={{
                    position: "relative",
                    bottom: 20,
                    fontSize: 12,
                    fontStyle: "italic",
                    color: 'grey',
                    display: showValue ? "block" : "none"
                }}
            >
                {/* (Tap on the icon above to expand) */}
            </div>
        }


    </div>

}


export default CottonTrace