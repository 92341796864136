import React from "react";
import RuLogo from "../assets/images/ruLogo.png";

import styled from "styled-components";

const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(206, 75, 85);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;


const CircleFigure = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  z-index: 2;
  position: relative;
  /* border: 1px solid; */
`;

const SplashScreen = () => {

  return (
    <ParentContainer
    >
      <CircleFigure>
        <svg
          style={{ position: "absolute" }}
          xmlns="http://www.w3.org/2000/svg"
          width="403"
          height="500"
          viewBox="0 0 403 403"
        >
          <svg
            style={{
              position: "absolute",
              placeSelf: "center"
            }}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="401"
            height="525"
            viewBox="0 0 401 525"
          >
            <defs>
              <filter
                id="Rectangle_659"
                x="0"
                y="0"
                width="401"
                height="525"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="30" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="15" result="blur" />
                <feFlood floodColor="#2699fb" floodOpacity="0.102" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#Rectangle_659)"
            >
              <rect
                id="Rectangle_659-2"
                data-name="Rectangle 659"
                width="311"
                height="435"
                rx="6"
                transform="translate(45 15)"
                fill="#de8686"
              />
            </g>
          </svg>

          <g id="hero" transform="translate(14 -72)">
            <circle
              id="Ellipse_201"
              data-name="Ellipse 201"
              cx="201.5"
              cy="201.5"
              r="201.5"
              transform="translate(-14 72)"
              fill="#fff"
              opacity="0.2"
            />
            <circle
              id="Ellipse_203"
              data-name="Ellipse 203"
              cx="101.5"
              cy="101.5"
              r="101.5"
              transform="translate(86 172)"
              fill="#fff"
              opacity="0.5"
            />
            <circle
              id="Ellipse_204"
              data-name="Ellipse 204"
              cx="51.5"
              cy="51.5"
              r="51.5"
              transform="translate(136 222)"
              fill="#fff"
            />
          </g>
        </svg>
        <svg
          style={{ position: "absolute" }}
          xmlns="http://www.w3.org/2000/svg"
          width="243"
          height="243"
          viewBox="0 0 243 243"
        >
          <circle
            id="Ellipse_202"
            data-name="Ellipse 202"
            cx="121.5"
            cy="121.5"
            r="121.5"
            fill="#fff"
            opacity="0.2"
          />
        </svg>

        <div style={{ position: "relative", textAlign: "center" }}>
          <img src={RuLogo} alt="ru-logo" style={{ maxWidth: 200 }} />

          <div
            style={{
              color: "white",
              fontWeight: "bold",
              textShadow: "0 0 5px black"
            }}
          >
            Getting trace details...
          </div>
        </div>

      </CircleFigure>
    </ParentContainer>
  );
};

export default SplashScreen;
