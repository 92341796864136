// Components / Hooks from libraries 
import {
    useEffect,
    //  useLayoutEffect,
    useState
} from "react"
import axios from "axios" //for making HTTP requests
// import ReactGA from "react-ga"; //for Google Analytics
import { animated, useTransition } from "react-spring"; //for animation
import MetaTags from 'react-meta-tags';


// Custom Components
import CottonTrace from "./main";

// Context
import TraceContext from "../../contexts/traceContext";
import SplashScreen from "../splashScreenMain";
import opacityTransitionPreset from "../../constants/opacityTransitionPreset";
import { sortByPic } from "../../functions/utils";

import RuLogo from "../../assets/images/ruLogo.png"

/**
 * CottonTraceInitiator is a component which will:
 * - Fetch the relevant data using HTTP calls
 * - Load a splash screen while the data is being fetched
 * - Model the data for better rendering (e.g., sorts the list of farmers by those who have pics first)
 * - Will act as Context Provider for the Context API
 * - Will start Google Analytics
 * @param {Object} props - Props object, used to get the ID from the URL 
 */

const staticData = {
    "id": "1710",
    "oracleId": "1710",
    "farmer": [
        {
            "id": "SF00012137",
            "name": "Ashok Sakharam Yadav",
            "picture": ""
        },
        {
            "id": "SF00012217",
            "name": "vijaykumar murlidhar khule",
            "picture": ""
        },
        {
            "id": "SF00012314",
            "name": "delete khule",
            "picture": ""
        },
        {
            "id": "SF00012317",
            "name": "Ashok sampati yadav",
            "picture": ""
        },
        {
            "id": "SF00012348",
            "name": "miradevi shamkumar khule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/e0657d04-1dce-11eb-85e5-1209878e27ac.jpg"
        },
        {
            "id": "SF00012351",
            "name": "usha vijaykumar khule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/1bfc9b10-1dd2-11eb-812f-caac79179be7.jpg"
        },
        {
            "id": "SF00012353",
            "name": "chaya abasaheb khule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/f6df8c18-1e47-11eb-94ce-8642c008f357.jpg"
        },
        {
            "id": "SF00012354",
            "name": "Lakshman ramkisan yadav",
            "picture": ""
        },
        {
            "id": "SF00012360",
            "name": "arvind anurath ingle",
            "picture": ""
        },
        {
            "id": "SF00012362",
            "name": "Vilas Ashruba Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012365",
            "name": "uttam sopan panchal",
            "picture": ""
        },
        {
            "id": "SF00012369",
            "name": "Manik Tryambak Farake",
            "picture": ""
        },
        {
            "id": "SF00012370",
            "name": "Ashruba Rambhau Farake",
            "picture": ""
        },
        {
            "id": "SF00012371",
            "name": "Bhagwat Ashruba Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012372",
            "name": "shamkumar murlidhar khule",
            "picture": ""
        },
        {
            "id": "SF00012382",
            "name": "Sanjay bansi Landage",
            "picture": ""
        },
        {
            "id": "SF00012387",
            "name": "Amol Bhagvat Landage",
            "picture": ""
        },
        {
            "id": "SF00012388",
            "name": "Ganesh Shivaji Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012389",
            "name": "Navnath Shesherav Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012390",
            "name": "Suresh Shivaji Nakhate",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/70ac5b0a-20bb-11eb-bee2-0679a2f7371e.jpg"
        },
        {
            "id": "SF00012391",
            "name": "Shivaji Dattu Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012393",
            "name": "vijaykumar bansidhar landge",
            "picture": ""
        },
        {
            "id": "SF00012394",
            "name": "Subhash Dattu Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012395",
            "name": "Parmeshwar Sahebrao Kothule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/57c91800-4805-11eb-ac9f-7a813be0983e.jpg"
        },
        {
            "id": "SF00012396",
            "name": "Mahesh Shivaji Solanke",
            "picture": ""
        },
        {
            "id": "SF00012397",
            "name": "Satyavan Bhagwan Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012398",
            "name": "Ramesh Ghanshyam Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012399",
            "name": "Rahul Ankush Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012414",
            "name": "Vitthal Pandurang yadav",
            "picture": ""
        },
        {
            "id": "SF00012415",
            "name": "Rukmin Vitthal Yadav",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/b9def6d8-1fdb-11eb-991b-a2ebaaa7e4df.jpg"
        },
        {
            "id": "SF00012416",
            "name": "Chatrabhuj Namdev Landage",
            "picture": ""
        },
        {
            "id": "SF00012420",
            "name": "Dinkar Maruti Panchal",
            "picture": ""
        },
        {
            "id": "SF00012421",
            "name": "Asaram Digambar Shinde",
            "picture": ""
        },
        {
            "id": "SF00012422",
            "name": "Digambar Gopal Shinde",
            "picture": ""
        },
        {
            "id": "SF00012423",
            "name": "Radhabai Anant Nakhate",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/d5adc50a-1ff5-11eb-a5a6-1e6fbc4b7abf.jpg"
        },
        {
            "id": "SF00012424",
            "name": "Suresh Mahadeo Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012426",
            "name": "Mankarna Manik Solanke",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/8a7d0d9a-1ffd-11eb-b097-32b458da9776.jpg"
        },
        {
            "id": "SF00012427",
            "name": "Balasaheb Manik Solanke",
            "picture": ""
        },
        {
            "id": "SF00012428",
            "name": "Ramesh Ravasaheb Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012431",
            "name": "Balasaheb Motiram Landage",
            "picture": ""
        },
        {
            "id": "SF00012432",
            "name": "Subhash Rambhau Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012447",
            "name": "Ashok Shankar Ingle",
            "picture": ""
        },
        {
            "id": "SF00012448",
            "name": "Sarubai Shankar Ingle",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/9ebc6646-2022-11eb-9af7-0679a2f7371e.jpg"
        },
        {
            "id": "SF00012455",
            "name": "Asha Chandrakant Khule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/c7d5e18e-2008-11eb-8dbd-32b458da9776.jpg"
        },
        {
            "id": "SF00012456",
            "name": "Mahadev Nagurav Solanke",
            "picture": ""
        },
        {
            "id": "SF00012457",
            "name": "Nagurav Rambhau Solanke",
            "picture": ""
        },
        {
            "id": "SF00012458",
            "name": "Sandip  Chandrakant Khule",
            "picture": ""
        },
        {
            "id": "SF00012460",
            "name": "Mahadev Namdev Bobade",
            "picture": ""
        },
        {
            "id": "SF00012463",
            "name": "Baliram Namdev Bobade",
            "picture": ""
        },
        {
            "id": "SF00012466",
            "name": "Sudhakar Eknath Mutthal",
            "picture": ""
        },
        {
            "id": "SF00012467",
            "name": "Shivaji Shrihari Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012468",
            "name": "Vithal Raghunath Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012469",
            "name": "Angad Narahari Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012482",
            "name": "Abasaheb Dhondiram Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012483",
            "name": "Ashruba Piraji Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012485",
            "name": "Surekha Sudhakar Khule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/bbd77cfa-2178-11eb-8f47-5a1be86787ee.jpg"
        },
        {
            "id": "SF00012507",
            "name": "Sarjerav Ganpatrao Kumbhar",
            "picture": ""
        },
        {
            "id": "SF00012508",
            "name": "Kushavarta Chandrasen Tambure",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/11c76b08-2245-11eb-8795-8a63adc785de.jpg"
        },
        {
            "id": "SF00012532",
            "name": "Sunanda Anantrav Khule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/eb54ac5e-2304-11eb-ba6f-7af48816e8c0.jpg"
        },
        {
            "id": "SF00012533",
            "name": "Janardhan Mahadev Kumbhar",
            "picture": ""
        },
        {
            "id": "SF00012534",
            "name": "Santosh Harichandra Kumbhar",
            "picture": ""
        },
        {
            "id": "SF00012535",
            "name": "Somnath Gopal Kumbhar",
            "picture": ""
        },
        {
            "id": "SF00012538",
            "name": "Shardabai Ashruba Kumbhar",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/3ff307a0-231f-11eb-8ac9-82ded6588b31.jpg"
        },
        {
            "id": "SF00012539",
            "name": "Mahananda Dilip Tambure",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/4122c598-231f-11eb-9d21-46c339df5fe5.jpg"
        },
        {
            "id": "SF00012544",
            "name": "Annapurna Vasant Raut",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/af7eaaec-232f-11eb-861c-7af48816e8c0.jpg"
        },
        {
            "id": "SF00012547",
            "name": "Shahaji Shivaji Nakhate",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/9793a81c-23b9-11eb-8935-f2c3e85fb658.jpg"
        },
        {
            "id": "SF00012557",
            "name": "Bandu Ashruba Farake",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/c2a2ea58-4804-11eb-a421-b29e066e68ea.jpg"
        },
        {
            "id": "SF00012618",
            "name": "Surykant Uttareshrwar Khule",
            "picture": ""
        },
        {
            "id": "SF00012619",
            "name": "Tukaram Dhondiram Yadav",
            "picture": ""
        },
        {
            "id": "SF00012620",
            "name": "Sakharam Kisan Kothule",
            "picture": ""
        },
        {
            "id": "SF00012692",
            "name": "Palinkabai Ashruba Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012710",
            "name": "Sakharbai Murlidhar Solanke",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/337add9a-2ba3-11eb-8bf3-4e978f7ec26c.jpg"
        },
        {
            "id": "SF00012717",
            "name": "Kanta Anurath Ingle",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/d768919c-2bd8-11eb-879a-6e87c0d5768c.jpg"
        },
        {
            "id": "SF00012840",
            "name": "Tryambak Rambhau Farake",
            "picture": ""
        },
        {
            "id": "SF00012917",
            "name": "Rajabhau Baburav Khule",
            "picture": ""
        },
        {
            "id": "SF00012920",
            "name": "Manoj Rajabhau khule",
            "picture": ""
        },
        {
            "id": "SF00012924",
            "name": "Bhagyashri Vilas Khule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/1ccce336-3085-11eb-b6e1-3665f4b3fb49.jpg"
        },
        {
            "id": "SF00012926",
            "name": "Kamalakar Baburao Khule",
            "picture": ""
        },
        {
            "id": "SF00012927",
            "name": "Sundar Vilasrao Khule",
            "picture": ""
        },
        {
            "id": "SF00012935",
            "name": "Ajit Vachishta Ingle",
            "picture": ""
        },
        {
            "id": "SF00012936",
            "name": "Vikas Dattatray Ingle",
            "picture": ""
        },
        {
            "id": "SF00012937",
            "name": "Rajendra Dattatray Ingle",
            "picture": ""
        },
        {
            "id": "SF00012938",
            "name": "Babruvahan Ravan Khule",
            "picture": ""
        },
        {
            "id": "SF00012939",
            "name": "Ganesh Babruvahan Khule",
            "picture": ""
        },
        {
            "id": "SF00012943",
            "name": "Shardabai Ganeshrao Gotawale",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/b7ef634c-3167-11eb-b28d-fa7f9ab0fdc4.jpg"
        },
        {
            "id": "SF00012944",
            "name": "Parvati Balasaheb Khule",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/ebf9327c-3170-11eb-a986-46840e46c9b1.jpg"
        },
        {
            "id": "SF00012945",
            "name": "Balasaheb Pandhari Khule",
            "picture": ""
        },
        {
            "id": "SF00012948",
            "name": "Vasant Shripati ingle",
            "picture": ""
        },
        {
            "id": "SF00012949",
            "name": "Mahadev Tatyaba Burse",
            "picture": ""
        },
        {
            "id": "SF00012950",
            "name": "Yashvant Tatyaba Burse",
            "picture": ""
        },
        {
            "id": "SF00012951",
            "name": "Govindrao Ravsaheb Solanke",
            "picture": ""
        },
        {
            "id": "SF00012960",
            "name": "Apparao Baburao Solanke",
            "picture": ""
        },
        {
            "id": "SF00012961",
            "name": "Subhash Rambhau Thorat",
            "picture": ""
        },
        {
            "id": "SF00012965",
            "name": "Surekha Shriram Yadav",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/ad856634-32fe-11eb-9738-eac6283bd1ee.jpg"
        },
        {
            "id": "SF00012971",
            "name": "Ramkisan Maroti Chole",
            "picture": ""
        },
        {
            "id": "SF00012972",
            "name": "Shreemant Bajirav Chole",
            "picture": ""
        },
        {
            "id": "SF00012975",
            "name": "Sudhakar Bhimrao Chole",
            "picture": ""
        },
        {
            "id": "SF00012980",
            "name": "Satish Dattatray Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012981",
            "name": "Lahudas Sambhajirao Yadav",
            "picture": ""
        },
        {
            "id": "SF00012983",
            "name": "Sahebrao Kisan Kothule",
            "picture": ""
        },
        {
            "id": "SF00012984",
            "name": "Shesherav Raghu Nakhate",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/f1c4f97a-3fb7-11eb-a31f-2a92f7874ff7.jpg"
        },
        {
            "id": "SF00012985",
            "name": "Jankabai Shesherav Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012986",
            "name": "Vasant Dattuba Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012989",
            "name": "Ranjana Amol Landge",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/dec22660-3466-11eb-bcb6-ba11a6b9a10e.jpg"
        },
        {
            "id": "SF00012990",
            "name": "Ramkisan Namdev Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012994",
            "name": "Mahesh Shivram Landge",
            "picture": ""
        },
        {
            "id": "SF00012995",
            "name": "Bhaskar Ramkisan Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012996",
            "name": "Bhagvan Namadev Nakhate",
            "picture": ""
        },
        {
            "id": "SF00012997",
            "name": "Ashok Bhagwan Nakhate",
            "picture": ""
        },
        {
            "id": "SF00013003",
            "name": "Jayshri Satyavan Nakhate",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/2fadc110-352a-11eb-9f45-1ebca1879e5d.jpg"
        },
        {
            "id": "SF00013023",
            "name": "Namdev Ashruba Dongre",
            "picture": ""
        },
        {
            "id": "SF00013024",
            "name": "Laxmibai Ashruba Dongre",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/cd0c9dd8-383f-11eb-a1e2-1e06a00e45f3.jpg"
        },
        {
            "id": "SF00013025",
            "name": "Suryakant Sampati Yadav",
            "picture": ""
        },
        {
            "id": "SF00013026",
            "name": "Ganesh Shivaji Solanke",
            "picture": ""
        },
        {
            "id": "SF00013030",
            "name": "Anita Angad Nakhate",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/2e04751c-38f8-11eb-9d0b-ee644490b492.jpg"
        },
        {
            "id": "SF00013045",
            "name": "Shrikrishna Apparao Tambure",
            "picture": ""
        },
        {
            "id": "SF00013052",
            "name": "Ajaykumar Murlidhar Khule",
            "picture": ""
        },
        {
            "id": "SF00013129",
            "name": "Mahadeev Uddhavrav Jagtap",
            "picture": ""
        },
        {
            "id": "SF00013130",
            "name": "Paratibha Kalyan Nakhate",
            "picture": "https://media.kisanhub.in/media/uploads/users/9552523089/b5fc2b22-3907-11eb-8be0-6ed6066b6b0b.jpg"
        },
        {
            "id": "SF00013131",
            "name": "Kalyan Ekanath Nakhate",
            "picture": ""
        },
        {
            "id": "SF00013186",
            "name": "Palinkabai Haridas Solanke",
            "picture": "https://media.kisanhub.in/media/uploads/users/9529017005/374abde4-3ec9-11eb-bdab-52f9f9d87f81.jpg"
        },
        {
            "id": "SF00013188",
            "name": "Nandubai Subhash Nakhate",
            "picture": ""
        }
    ],
    "artisan": [
        {
            "id": "1",
            "name": "arachana",
            "picture": "/media/artisan/arachana.jpg"
        },
        {
            "id": "2",
            "name": "chhaya",
            "picture": "/media/artisan/chhaya.jpg"
        },
        {
            "id": "3",
            "name": "dipali",
            "picture": "/media/artisan/dipali.jpg"
        },
        {
            "id": "4",
            "name": "firdos",
            "picture": "/media/artisan/firdos.jpg"
        },
        {
            "id": "5",
            "name": "joyti",
            "picture": "/media/artisan/joyti.jpg"
        },
        {
            "id": "6",
            "name": "jyoti",
            "picture": "/media/artisan/jyoti.jpg"
        },
        {
            "id": "7",
            "name": "kavita",
            "picture": "/media/artisan/kavita.jpg"
        },
        {
            "id": "8",
            "name": "kavitha",
            "picture": "/media/artisan/kavitha.jpg"
        },
        {
            "id": "9",
            "name": "madhuri",
            "picture": "/media/artisan/madhuri.jpg"
        },
        {
            "id": "10",
            "name": "manisha",
            "picture": "/media/artisan/manisha.jpg"
        },
        {
            "id": "11",
            "name": "megha",
            "picture": "/media/artisan/megha.jpg"
        },
        {
            "id": "12",
            "name": "monika",
            "picture": "/media/artisan/monika.jpg"
        },
        {
            "id": "13",
            "name": "nelofar",
            "picture": "/media/artisan/nelofar.jpg"
        },
        {
            "id": "14",
            "name": "prem patil",
            "picture": "/media/artisan/prem patil.jpg"
        },
        {
            "id": "15",
            "name": "pushpa",
            "picture": "/media/artisan/pushpa.jpg"
        },
        {
            "id": "16",
            "name": "sadhana",
            "picture": "/media/artisan/sadhana.jpg"
        },
        {
            "id": "17",
            "name": "sandhya",
            "picture": "/media/artisan/sandhya.jpg"
        },
        {
            "id": "18",
            "name": "sarika",
            "picture": "/media/artisan/sarika.jpg"
        },
        {
            "id": "19",
            "name": "savita",
            "picture": "/media/artisan/savita.jpg"
        },
        {
            "id": "20",
            "name": "seema",
            "picture": "/media/artisan/seema.jpg"
        },
        {
            "id": "21",
            "name": "shirin",
            "picture": "/media/artisan/shirin.jpg"
        },
        {
            "id": "22",
            "name": "shital",
            "picture": "/media/artisan/shital.jpg"
        },
        {
            "id": "23",
            "name": "shobha",
            "picture": "/media/artisan/shobha.jpg"
        },
        {
            "id": "24",
            "name": "swati",
            "picture": "/media/artisan/swati.jpg"
        },
        {
            "id": "25",
            "name": "ujwala",
            "picture": "/media/artisan/ujwala.jpg"
        },
        {
            "id": "26",
            "name": "vaishali",
            "picture": "/media/artisan/vaishali.jpg"
        },
        {
            "id": "27",
            "name": "yogita",
            "picture": "/media/artisan/yogita.jpg"
        }
    ],
    "mrp": 0,
    "consumerImage": "",
    "consumerName": "",
    "v": 0,
    "sku": {
        "blockchain": "cotton-prod-1",
        "id": "1",
        "oracleId": "",
        "name": "s SKU",
        "costingId": "1",
        "eanCode": "0000000000000"
    },
    "costing": {
        "id": 81,
        "tag": "RUCOTRN-A1AP01-UNaBluSCS",
        // "farmerEarning": {
        //     "total": 12.3,
        //     "contribution": 5.9,
        //     "firm": 42.0
        // },
        "farmerEarning": {
            "total": null,
            "contribution": null,
            "firm": null
        },
        "harvest": null,
        "processing": {
            "cost": null,
            "data": [
                {
                    "name": "ginning",
                    "location": {
                        "id": "2",
                        "type": "Processing Facility",
                        "name": "Narmada Fibres LLP",
                        "description": "",
                        "images": [],
                        "address": {
                            "location": {
                                "type": "Point",
                                "coordinates": [
                                    18.8013047,
                                    76.1102792
                                ]
                            },
                            "name": "Narmada Fibres LLP",
                            "localArea": "Kille Dharur",
                            "village": "DHARUR",
                            "taluka": "",
                            "district": "BEED",
                            "state": "Maharashtra",
                            "country": "India",
                            "pincode": "",
                            "rawAddress": "Narmada Fibres LLP, Kille Dharur, Dist- Beed, Maharashtra"
                        }
                    },
                    "cost": null
                },
                {
                    "name": "spinning",
                    "location": {
                        "id": "3",
                        "type": "Processing Facility",
                        "name": "Deendayal Magaswargiya Sahakari Sut Girni Ltd",
                        "description": "",
                        "images": [],
                        "address": {
                            "location": {
                                "type": "Point",
                                "coordinates": [
                                    17.0214798,
                                    74.2437789
                                ]
                            },
                            "name": "Deendayal Magaswargiya Sahakari Sut Girni Ltd",
                            "localArea": "",
                            "village": "Waghwadi",
                            "taluka": "Walwa",
                            "district": "Sangli",
                            "state": "Maharashtra",
                            "country": "India",
                            "pincode": "",
                            "rawAddress": "Deendayal Magaswargiya Sahakari Sut Girni Ltd, Waghadi, Tal- Walwa, Dist- Sangli, Maharashtra"
                        }
                    },
                    "cost": null
                },
                {
                    "name": "knitting & weaving",
                    "location": {
                        "id": "4",
                        "type": "Processing Facility",
                        "name": "Shree.Renukamata Knitting and  Garment",
                        "description": "",
                        "images": [],
                        "address": {
                            "location": {
                                "type": "Point",
                                "coordinates": [
                                    16.7086212,
                                    74.1688269
                                ]
                            },
                            "name": "Shree.Renukamata Knitting and  Garment",
                            "localArea": "Hatkanangale",
                            "village": "Ichalkaranji",
                            "taluka": "Kolhapur",
                            "district": "Kolhapur",
                            "state": "Maharashtra",
                            "country": "India",
                            "pincode": "",
                            "rawAddress": "Shree.Renukamata Knitting and  Garment, Hatkanangale, Ichalkaranji, Dist- Kolhapur, Maharashtra"
                        }
                    },
                    "cost": null
                },
                {
                    "name": "dyeing",
                    "location": {
                        "id": "5",
                        "type": "Processing Facility",
                        "name": "Ichalkaranji Enterprises Private Limited",
                        "description": "",
                        "images": [],
                        "address": {
                            "location": {
                                "type": "Point",
                                "coordinates": [
                                    16.7043074,
                                    74.429025
                                ]
                            },
                            "name": "Ichalkaranji Enterprises Private Limited",
                            "localArea": "MAGDUM COLONY",
                            "village": "ICHALKARANJI",
                            "taluka": "ICHALKARANJI",
                            "district": "SANGLI",
                            "state": "Maharashtra",
                            "country": "India",
                            "pincode": "",
                            "rawAddress": "Ichalkaranji Enterprises Private Limited, Hatkanangale, Ichalkaranji, Dist- Kolhapur, Maharashtra"
                        }
                    },
                    "cost": null
                }
            ]
        },
        "postProcessing": {
            "cost": null,
            "data": [
                {
                    "name": "extra material",
                    "cost": null
                },
                {
                    "name": "labor",
                    "cost": null
                },
                {
                    "name": "overheads",
                    "cost": null
                },
                {
                    "name": "financing",
                    "cost": null
                },
                {
                    "name": "designing",
                    "cost": null
                },
                {
                    "name": "marketing",
                    "cost": null
                },
                {
                    "name": "operating margin",
                    "cost": null
                }
            ]
        },
        "totalCost": null,
        "retail": {
            "location": 6,
            "price": null
        }
    },
    "clickCount": 0
}

const CottonTraceInitiator = (props) => {

    /**
     * States variables of the component
     */

    const [traceData, setTraceData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isManuallyWaiting, setIsManuallyWaiting] = useState(true) //we start a manual timer, which will last a few seconds (even if the trace data is loaded) so that the splash screen can be seen better (UX tweak)

    /**
     * Fetch the trace data, model it a bit, and then set it in the state variable to show the trace
     */
    const getAndSetTraceDetails = async () => {
        setIsLoading(true)
        const { id } = props.match?.params;
        let fgId = id;
        try {
            const { data } = await axios.get("https://sfsgil.agrotrust.io/api/trace/cotton/" + fgId);
            // const data = staticData
            // console.log(data)
            setTraceData({
                ...data,
                farmer: data.farmer.sort(sortByPic),
            });

            setIsLoading(false);
        } catch (error) {
            window.alert(error.message)
        }
    }

    const updateImageInTrace = async ({ consumerName, consumerImage }) => {
        let _temp = { ...traceData }

        _temp.consumerImage = consumerImage
        _temp.consumerName = consumerName

        setTraceData(_temp)
    }

    /**
     * transition function to animate the components
     */
    const opacityTransition = useTransition(!isLoading && !isManuallyWaiting, opacityTransitionPreset)


    /**
     * code to run on first mount. Will manually start a countdown timer as explained in `isManuallyWaiting` state variable's comment
     */
    useEffect(() => {
        getAndSetTraceDetails()
        setTimeout(() => {
            setIsManuallyWaiting(false);
        }, 2000);
    }, [])


    return <TraceContext.Provider value={{
        ...traceData,
        updateImageInTrace
    }}>
        <MetaTagsRenderer
            consumerImage={traceData?.consumerImage}
            consumerName={traceData?.consumerName}
        />
        {opacityTransition((style, item) => item ? <animated.div style={style}><CottonTrace /></animated.div> : <animated.div style={style}>
            <SplashScreen />
        </animated.div>)}
    </TraceContext.Provider>

}



const MetaTagsRenderer = ({ consumerImage, consumerName }) => {

    const pageTitle = "RU Trace for Cotton Supply Chain by AgroTrust, a Grassroots Initiative"
    const metaDescription = consumerName ? `View ${consumerName}'s role in a complete trace throughout the cotton supply chain` : "RU presents complete end-to-end traceability and transparency throughout the cotton supply chain"
    const metaImageUri = consumerImage ? "https://sfsgil.agrotrust.io" + consumerImage : RuLogo
    const metaWebsiteUrl = window.location.href

    const metaTitle = consumerName ? `RU thanks ${consumerName} for being a part of our trace!` : "RU Trace for Cotton Supply Chain by AgroTrust, a Grassroots Initiative"

    return (
        <MetaTags>
            <title>{pageTitle}</title>
            <meta name="title" content={metaTitle} />
            <meta name="description" content={metaDescription} />

            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaWebsiteUrl} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageUri} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={metaWebsiteUrl} />
            <meta property="twitter:title" content={metaTitle} />
            <meta property="twitter:description" content={metaDescription} />
            <meta property="twitter:image" content={metaImageUri} />
        </MetaTags>
    )
}

export default CottonTraceInitiator