import styled from "styled-components";

// import blockchain from "../../assets/images/blockchain.png";
// import fpo from "../../assets/images/fpo.png";
// import whatHappens from "../../assets/images/what-happens.png";

import whn1 from "../../assets/images/modalImages/cottonExploitationSm.png"
import whn3 from "../../assets/images/modalImages/womanWithCottonSm.png"
import whn2 from "../../assets/images/modalImages/farmerWithSack.png"
import whn4 from "../../assets/images/modalImages/moneyInHand.png"
import whn5 from "../../assets/images/modalImages/farmerGettingMoney.png"

import init1 from "../../assets/images/ruLogo.png"
import init2 from "../../assets/images/modalImages/peopleOfSahyadri.png"


import alpsBg from "../../assets/images/alpsBg.jpg";
import close from "../../assets/images/close.png";

import ReactModal from "react-modal";
import { useState, useContext } from "react";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "../../assets/styles/slideshow.css"
import TraceContext from "../../contexts/traceContext";

export const TopHead = styled.div`
  height: 150px;
  width: 100%;
  background: no-repeat center;
  /* background-image: url("https://anywhereweroam.com/wp-content/uploads/2019/12/italian-alps-8.jpg"); */
  background-image: ${props => `url(${props.bgImg || alpsBg})`};
  background-size: cover;
  border-radius: 0 0 25px 25px;
  position: relative;
`;

const HelloContainer = styled.div`
  background-color: #fffffff5;
  padding: 5px;
  position: absolute;
  top: -5px;
  margin: 10px;
  border-radius: 10px;
`;

const RestOfBody = styled.div`
  margin: 20px 20px -20px;
  display: flex;
  justify-content: space-between;
`;

const OptionBox = styled.div`
  cursor: pointer;
  outline: none;
  border: none;

  background-color: white;
  position: relative;
  bottom: 40px;

  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0px #e4e4e4;
  text-align: center;
  width: 25%;
`;

const ModalStyle = {
  overlay: {
    zIndex: 3,
    backgroundColor: "#000000ad",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    position: "static",
    width: "90%"
  }
};

const CloseBtnStyle = {
  position: "absolute",
  top: -20,
  right: -20,
  boxSizing: "border-box",
  padding: 10,
  width: 50
};

const blockchainSlideInfo = [
  {
    img: "https://qriosity.agrotrust.io/images/trust.png",
    text:
      "Do you trust some data only because you can see it on your screen? Only because it's digitised doesn't ensure trust."
  },
  {
    img: "https://qriosity.agrotrust.io/images/blockchain.png",
    text:
      "We call it 'AgroTrust', because our Trust comes from Blockchain Technology. Once a data enters Blockchain, it can't be edited or deleted. It's written in 'digital stone', forever. Blockchain Technology gives meaning to our values of Trust and Transparency, because what you see in front of you is presented to you with the help of Blockchain."
  },
  {
    img: "https://qriosity.agrotrust.io/images/future.png",
    text:
      "Blockchain Technology is the future of every domain or business which depends on the values of trust and transparency. It's properties of Immutability, Irrefutability, and others help us deliver this important information to you in a secure manner. We encourage everyone to understand this technology that is meant to shape our future, and be a part of this technological revolution."
  }
];

const sliderConfig = {
  // indicators: true,
  arrows: false,
  indicators: i => <div className="indicator">{/* {i + 1} */}</div>
};

const BlockchainSlideShow = () => {
  return (
    <div>
      <Slide easing="ease" {...sliderConfig}>
        {blockchainSlideInfo.map(slide => (
          <div className="each-slide">
            <div
              className="slide-bg"
              style={{ backgroundImage: `url(${slide.img})` }}
            ></div>
            <div className="text">{slide.text}</div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

const initiativeSlideInfo = [
  {
    img: init1,
    text:
      "RU - Rural Urban Connect is the world’s first initiative connecting grassroot level innovations to cater the needs of the global citizens, introduced by the farmers of Sahyadri Farms Sustainable Grassroots Initiatives Limited (SFSGIL) from India."
  },
  {
    img: init2,
    text:
      "This FPC is formed by approximately 300 farmers from 99 families holding around 400 acres of cotton cultivated land. The entire journey of the process, right from picking to making the T-Shirt you have right now, is supervised and controlled by SFSGIL team."
  },
  {
    img: whn5,
    text:
      "Our farmers are the integral part of our army. They deserve to be acclaimed for every ounce of effort they put in to make this world a better living place. Our aim is to provide for the fair and equitable life they deserve, and for this, our Grassroots Initiative is designed in such a way that we offer farmers prices higher than or equal to the minimum support price in comparison with prevailing market price, ensuring that our farmers are financially secure and healthy."
  }
];

const InitiativeSlideshow = () => {
  return (
    <div>
      <Slide easing="ease" {...sliderConfig}>
        {initiativeSlideInfo.map(slide => (
          <div className="each-slide">
            <div
              className="slide-bg"
              style={{ backgroundImage: `url(${slide.img})` }}
            ></div>
            <div className="text">{slide.text}</div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

const whatHappensInfo = [
  {
    img: whn1,
    text:
      "We are so careful when deciding what to wear, right from the fabric, colour, design, to price? We check for everything that is important to us. And although clothing has been fundamental to human life since the beginning of civilisation, but yet, what happens in the entire value chain from cotton seeds till your t-shirts is lesser known. "
  },
  {
    img: whn2,
    text:
      "In terms of human effort, labour and risks that are involved in bringing clothes to your nearby store, it is the farmer and farm labour who contribute a significant portion. At such times, we don't need any charitable outlook for supporting initiatives like ours, but an understanding of fair equity and rightful compensations. "
  },
  {
    img: whn3,
    text:
      "Do you know how much of your money in buying clothing is reaching the farmer? If you pay more than what you normally pay, who would gain that additional money? When you read news about farmer suicides, especially cotton farmer suicides, isn't there some connection in the way the entire see-saw of the value chain is tilted against the end point: our farmers? What is the general story of over 9 crore cotton farming households in India whose labour and subsequent produce powers over 38% agro-exports in India?"
  },
  {
    img: whn4,
    text: `In a "normal" scenario, the farmers are treated as "squeezable" entities who are mostly paid less than the stipulated Minimum Support Price declared by the government after studying the production costs involved in it. Any surplus value created on top of this labour and produce is owned directly by the end points, with no linkages at all. But why aren't farmers' livelihoods and dignified incomes considered the core of everyone's 'sustainable' philosophy brand?`
  },

  {
    img: whn5,
    text: `Why can't farmers become stakeholders who can ask for a dividend on the profits given that it's their labour and sweat which is powering the value chain? This is what we have set out to do as a farmer-led initiative. We want to redefine what is considered "normal", and bring a revolutionary paradigm shift in the way we look at our value chains.
      `
  }
];

const WhatHappensSlideshow = () => {
  return (
    <div>
      <Slide easing="ease" {...sliderConfig}>
        {whatHappensInfo.map(slide => (
          <div className="each-slide">
            <div
              className="slide-bg"
              style={{ backgroundImage: `url(${slide.img})` }}
            ></div>
            <div className="text">{slide.text}</div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

const ModalButtons = () => {
  const [showBlockchain, setShowBlockchain] = useState(false);
  const [showInitiative, setShowInitiative] = useState(false);
  const [showWhatHappens, setShowWhatHappens] = useState(false);

  const closeBcModal = () => {
    setShowBlockchain(false);
  };

  const closeInitiative = () => {
    setShowInitiative(false);
  };

  const closeWhatHappens = () => {
    setShowWhatHappens(false);
  };

  const context = useContext(TraceContext);
  // console.log(226, context);
  const farmerEarned = context?.data?.costing?.harvest?.toFixed(1);
  const totalAmt = context?.data?.costing?.retail?.price.toFixed(1);
  const artisansEarned = context.data?.costing?.postProcessing?.data
    ?.find(el => el.name.includes("labor"))
    ?.cost?.toFixed(1);

  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        {/* Blockchain Modal */}
        <ReactModal
          appElement={document.getElementById("app")}
          style={ModalStyle}
          isOpen={showBlockchain}
          contentLabel="Why to use Blockchain?"
          onRequestClose={closeBcModal}
          shouldCloseOnOverlayClick={true}
        >
          <div style={{ position: "relative" }}>
            <div style={CloseBtnStyle} onClick={closeBcModal}>
              <img alt="Close" src={close} />
            </div>

            <h3 style={{ margin: 0, textAlign: "center" }}>Why Blockchain?</h3>
            <BlockchainSlideShow />
          </div>
        </ReactModal>

        {/* Farmer Initiative Modal */}
        <ReactModal
          appElement={document.getElementById("app")}
          style={ModalStyle}
          isOpen={showInitiative}
          contentLabel="Grassroots Being the First Farmer Initiative of its kind"
          onRequestClose={closeInitiative}
          shouldCloseOnOverlayClick={true}
        >
          <div style={{ position: "relative" }}>
            <div style={CloseBtnStyle} onClick={closeInitiative}>
              <img alt="Close" src={close} />
            </div>

            <div style={{ textAlign: "center" }}>
              <h3 style={{ margin: 0 }}>Farmer Owned initiative</h3>
              <div style={{ fontSize: "0.7em", color: "grey" }}>
                First of its kind
              </div>
            </div>
            <InitiativeSlideshow />
          </div>
        </ReactModal>

        {/* What Happens Normally Modal */}
        <ReactModal
          appElement={document.getElementById("app")}
          style={ModalStyle}
          isOpen={showWhatHappens}
          contentLabel="What happens normally in a cotton supply chain"
          onRequestClose={closeWhatHappens}
          shouldCloseOnOverlayClick={true}
        >
          <div style={{ position: "relative" }}>
            <div style={CloseBtnStyle} onClick={closeWhatHappens}>
              <img alt="Close" src={close} />
            </div>

            <div style={{ textAlign: "center" }}>
              <h3 style={{ margin: 0 }}>What Happens Normally</h3>
              {/* <div style={{ fontSize: "0.7em", color: "grey" }}>
              First of its kind
            </div> */}
            </div>
            <WhatHappensSlideshow />
          </div>
        </ReactModal>
      </div>




      <div className="blue__section">
        <div style={{ display: "flex", color: "white", fontSize: 12 }}>

          <div className="action__btn__container" onClick={() => setShowWhatHappens(true)}>
            <div
              className="action__icon__btn" id="cotton__chain__exploitation"
            />
            <div>Cotton Chain Exploitation</div>
          </div>
          <div className="action__btn__container" onClick={() => setShowBlockchain(true)}>
            <div
              className="action__icon__btn"
              id="why__blockchain"
            ></div>
            <div>Why Blockchain?</div>
          </div>
          <div className="action__btn__container" onClick={() => setShowInitiative(true)}>
            <div
              className="action__icon__btn"
              id="farmer__owned__initiative"
            />
            <div>Farmer Owned Initiative</div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ModalButtons;
