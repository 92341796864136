import styled, { keyframes } from "styled-components";
import loading from "../assets/images/loading.svg";
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #ffffffcf;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
`;

const LoadingIcon = styled.img`
  max-width: 30vw;
  animation: ${rotate} 2s linear infinite;
  filter: hue-rotate(-75deg);
`;

const Loading = props => {
  return (
    <LoadingContainer>
      <LoadingIcon src={loading} alt="loading" width={props.width || "auto"} />
    </LoadingContainer>
  );
};

export default Loading;
