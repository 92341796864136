// import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./routing/Routing";
// import "./assets/styles/styles.css"
const App = () => {
  return (
      <Router>
        <Routing />
      </Router>
  );
};

export default App;
