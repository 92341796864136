import imageCompression from "browser-image-compression";

const compressImage = async (image) => {
    // const imageFile = event.target.files[0];
    const imageFile = image;
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
        maxSizeMB: 0.6,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    };
    try {
        let compressedFile = await imageCompression(imageFile, options);
        console.log(
            `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB
        return compressedFile;
    } catch (error) {
        console.log(error);
    }
};

export default compressImage