import { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import farmersHeader from "../../../../assets/images/farmersHeader.png";
import artisansHeader from "../../../../assets/images/artisansHeader.JPG";
import backButton from "../../../../assets/images/back.png";
import {
  LazyLoadImage,
  trackWindowScroll
} from "react-lazy-load-image-component";

import TraceContext from "../../../../contexts/traceContext";
import {
  Heading,
  TopHead,
  FarmerCircle,
  Container,
  ButtonTransp
} from "../../../../components/styled/StyledComponents";

import farmer1 from "../../../../assets/images/process/farming/1.jpg";
import farmer2 from "../../../../assets/images/process/farming/2.jpg";
import firstLetterUppercase from "../../../../utils/firstLetterUppercase";

const myGreen = "#adc486";
const myBlue = "#014d63"

const typeMapping = {
  farmers: {
    headerImg: farmer1,
    headerTitle: "The Farmers",
    showBalaghat: true,
    infoCard: {
      title: "Sahyadri Balaghat FPC.",
      location: "Balaghat, Maharashta",
      body: `We are farmers group producing cotton since generations. We recently came together formally, to make "Sahyadri Balaghat Farmer Producer Company i.e. Balaghat Farms"

        At Balaghat Farms we are adopting to techniques of Clean, Green, and Ethical practices so as to ensure sustainability of our farmlands. We have lent our complete support to initiatives of Blockchain-backed transparency. But sustainability needs support from every stakeholder, which is where we encourage our customers to look for ethically sourced and produced cotton in order to support our initiatives.`
    }
  },
  artisans: {
    headerImg: artisansHeader,
    headerTitle: "The Artisans",
    infoCard: {
      title: "Sahyadri Farms",
      location: "Nashik, Maharashta",
      body: `Our artisans are our conquerors. We aim to improve the lives of the men and women who make our products and give life to the apparel industry. Every artisan associated with RU directly or indirectly is strictly provided with equitable sums under the minimum wages act. We ensure 100% conformance and compliance to government salary regulation for our field of work for basic salary and DA for skilled/unskilled temporary workers. With better working conditions, fair wages, training opportunities and other benefits for their personality development, our artisans are all set to conquer the world with those amazing skills and techniques that they provide. We have locally hired, trained and employed over 50+ women workers. These women were chosen from a cluster of 16+ villages surrounding SFSGIL. As a result, we are able to effectively contribute to our various community development programmes.`
    }
  }
};

const Abstraction = ({ name, hideButton, scrollPosition }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _contextObj = useContext(TraceContext);
  console.log("ContextObj", _contextObj, name);

  const isFarmer = name.toLowerCase() === "farmers";

  let arrayToMap = _contextObj[isFarmer ? "farmer" : "artisan"];
  // let arrayToMap = isFarmer ? [] : _contextObj.data["artisan"];

  const placeholderImg =
    "https://www.pngitem.com/pimgs/m/421-4212617_person-placeholder-image-transparent-hd-png-download.png";
  return (
    <section
      // id="trace__parent"
      style={{
        maxWidth: 500,
        position: "relative",
        margin: "auto"
      }}
    >
      <Container>
        <ButtonTransp
          color="white"
          style={{
            position: "fixed",
            top: 5,
            left: 5,
            zIndex: 3,
            padding: 10
          }}
          onClick={() => hideButton()}
        >
          <i
            
          >
            <img style={{width: "1.5rem", height:"1.5rem", backgroundColor:"white", padding:"0.2rem", borderRadius:"50%"}} src={backButton}/>
          </i>
        </ButtonTransp>

        <TopHead
          style={{
            backgroundImage: `url(${typeMapping[name].headerImg})`,
            height: 170,
            backgroundPosition: "inherit"
          }}
        />
      </Container>
      <Heading className="bold">
        {typeMapping[name].headerTitle}
      </Heading>
      <div style={{ margin: "0 20px 20px" }}>
        <div
          className="white__box box__text__border"
        >
          <div style={{ fontWeight: "bold", fontSize: 20 }}></div>
          <div>{typeMapping?.farmers?.location}</div>
          <div style={{ fontSize: 13, whiteSpace: "pre-line" }}>
            {typeMapping[name].infoCard.body}
          </div>
        </div>

        <div style={{ padding: 15 }}>
          <div style={{ fontSize: 20, fontWeight: "bold", marginBottom: 20 }}>
            The Team
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between"
            }}
          >
            {arrayToMap
              // .slice(0, 10)
              .map((el, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      textAlign: "center",
                      marginBottom: 15,
                      width: "33%"
                    }}
                  >
                    <LazyLoadImage
                      alt={el.name}
                      height={80}
                      width={80}
                      effect="blur"
                      style={{
                        borderRadius: 15,
                        margin: "auto",
                        objectFit: "cover"
                      }}
                      // scrollPosition={scrollPosition}
                      placeholderSrc={placeholderImg}
                      src={
                        !el.picture
                          ? placeholderImg
                          : isFarmer
                            ? el.picture
                            : "https://sfsgil.agrotrust.io" + el.picture
                      }
                    />

                    {/* <FarmerCircle
                      style={{
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${
                          i < 5
                            ? isFarmer
                              ? el.picture
                              : "https://sfsgil.agrotrust.io" + el.picture
                            : placeholderImg
                        })`
                      }}
                    /> */}
                    <div style={{ fontSize: 10, paddingTop: 3 }}>
                      {firstLetterUppercase(el.name)}
                    </div>

                    <div style={{ fontSize: 10, paddingTop: 3 }}></div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(trackWindowScroll(Abstraction));
