import selfieIcon from "../../../assets/images/updated/selfie.png"
import ReactModal from "react-modal";
import { useState, useRef, useEffect, useContext, useLayoutEffect } from "react";
import compressImage from "../../../functions/compressImage";
import TraceContext from "../../../contexts/traceContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { capitalizeFirstLetter } from "../../../functions/utils";

const ModalStyle = {
    overlay: {
        zIndex: 3,
        backgroundColor: "#000000ad",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(10px)"
    },
    content: {
        position: "static",
        width: "90%"
    }
};


/**
 * SelfieSection is a component renders the Selfie section, along with the image upload and disclaimer modals
 * Shows image and name after the user has enterd information, otherwise shows the `click to upload images` text
 */
const SelfieSection = ({ consumerName, consumerImage }) => {

    const [showUploadModal, setShowUploadModal] = useState(false)


    const handleSelfieClick = () => {
        if (consumerImage) return;
        setShowUploadModal(true)
    }

    return <>
        <div
            onClick={handleSelfieClick}
            className="white__box"
            style={{ borderBottom: '2px solid #feb29c' }}
            id="selfie"
        >
            <div className="main__details">
                {consumerImage && <img src={"https://sfsgil.agrotrust.io" + consumerImage} style={{ maxWidth: 150, objectFit: "cover" }} alt="User" />}

                <div className="checkpoint">
                    <img src={selfieIcon} alt="selfie icon" style={{
                        opacity: 0.3,
                        width: 90
                    }} />
                    <div className="name">
                        {
                            (consumerName || consumerImage) ? consumerName : "Let your selfie be a part of our story!"
                        }
                    </div>
                    <div className="description" style={{ textAlign: 'center' }}>
                        {
                            consumerImage ? "Our esteemed customer" : "Upload your selfie by tapping here"
                        }
                    </div>
                </div>
            </div>
        </div>

        {/* Image Upload Modal */}
        {showUploadModal && <ImageUploadModal
            showModal={showUploadModal}
            setShowModal={setShowUploadModal}
        />}

    </>
}


const ImageUploadModal = ({ showModal, setShowModal }) => {

    const imgRef = useRef(null);
    const uploadRef = useRef();
    const nameRef = useRef();

    const [imageBlob, setImageBlob] = useState(null);
    const [consumerName, setConsumerName] = useState("");

    const [showDisclaimer, setShowDisclaimer] = useState(false)

    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams()
    const { updateImageInTrace } = useContext(TraceContext)


    // console.log(useContext(TraceContext), 92)

    // useEffect(() => {
    //     setImageByUrl(imgUri);
    //     if (!imgUri) {
    //         imgRef.current.click();
    //     }
    // }, []);


    /**
     * To remove the background scrolling when modal is open,
     * and to revert back to scroll when the modal is closed
     */
    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => document.body.style.overflow = 'unset'
    }, [])


    /**
     * When the image is changed and the name has not been entered yet, focus on the name field
     */
    useEffect(() => {
        if (imageBlob) {
            if (!consumerName) nameRef.current.focus()
        }
        //eslint-disable-next-line
    }, [imageBlob])


    const setImageByUrl = (imgUri) => {
        setImageBlob(imgUri || null);
    };

    const clearImage = () => {
        setSelectedFile(null);
        setImageBlob(null);
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setSelectedFile(img);
            setImageBlob(URL.createObjectURL(img));
            uploadRef.current.click();
        }
    };

    const uploadImage = async () => {
        if (!selectedFile) {
            window.alert("Please upload your pic to complete the trace!");
            return;
        }

        if (!consumerName.trim()) {
            window.alert("Please enter your name to complete the trace!");
            return;
        }

        setLoading(true)

        try {
            var formdata = new FormData();

            let imageBlob = await compressImage(selectedFile);


            // return;
            imageBlob.lastModifiedDate = new Date();
            imageBlob.name = consumerName.trim() || null;

            formdata.append("business", "sfsgil");
            formdata.append("fg_id", id);

            formdata.append("name", imageBlob.name);
            formdata.append("image", imageBlob);

            var config = {
                method: "post",
                url: "https://sfsgil.agrotrust.io/media/upload",
                data: formdata,
            };

            const response = await axios(config);

            console.log(response);

            if (response.status === 201) {
                updateImageInTrace({ consumerName, consumerImage: response.data.display_url.split("https://sfsgil.agrotrust.io")[1] })
                // split because the response from the server when the image is [already] uploaded doesn't contain the domain, but the response from the server after image is uploaded contains the domain name
                setShowModal(false)
            }
            // closeModal();
        } catch (error) {
            console.log(error.message);
            window.alert("Some error occurred:" + error.message);
        } finally {
            setLoading(false);
        }

    }

    return < ReactModal
        appElement={document.getElementById("root")}
        style={ModalStyle}
        isOpen={showModal}
        contentLabel="Upload your selfie to complete the trace!"
        onRequestClose={() => setShowModal(false)}
        shouldCloseOnOverlayClick={true}
    >

        <HiddenInputs
            imgRef={imgRef}
            onImgChange={onImageChange}
            uploadRef={uploadRef}
        />

        {loading && <Loading />}

        <div
            className="selfie__modal__parent"
        >
            <div className="title__close__btns">
                <div></div>
                <div className="bold text__center title__text">
                    Upload your selfie image
                </div>
                <div className="close__btn__container">
                    <button
                        id="close__btn"
                        onClick={() => setShowModal(false)}
                    ></button>
                </div>
            </div>
            <div className="container">

                {
                    imageBlob ? <><UploadedImage imgUrl={imageBlob} />
                        <div className="input__disc__container">
                            <div className="name__label">Enter your name</div>
                            <input
                                type="text"
                                className="name__input"
                                ref={nameRef}
                                onChange={(e) => setConsumerName(capitalizeFirstLetter(e.target.value))}
                                value={consumerName}
                            />
                            <div>
                                <button
                                    className="disclaimer__btn"
                                    onClick={() => setShowDisclaimer(true)}
                                >
                                    By uploading the image, you give your consent according to
                                    our <span> terms of use</span>. Tap here to read them.
                                </button>
                            </div>
                        </div>

                    </> :
                        <div
                            className="upload__container"
                            onClick={() => imgRef.current.click()}
                        >
                            <div className="leaf top" />
                            <div className="leaf bottom" />

                            <div>
                                <div style={{
                                    color: "brown",
                                    fontWeight: "bold"
                                }}>Please upload your image to be a part of the trace!</div>
                                <div style={{ fontSize: "0.8em" }}>Tap here to upload</div>
                            </div>
                        </div>
                }


                <div className="action__btns__container">
                    <button
                        onClick={() => imgRef.current.click()}
                        className="action__btn retake">
                    </button>
                    <button
                        className="action__btn submit"
                        onClick={uploadImage}
                        disabled={!imageBlob || !consumerName}
                    ></button>
                </div>
            </div>
        </div>


        <ReactModal
            appElement={document.getElementById("root")}
            style={ModalStyle}
            isOpen={showDisclaimer}
            contentLabel="Disclaimer for uploading content"
            onRequestClose={() => setShowDisclaimer(false)}
            shouldCloseOnOverlayClick={true}
        >
            <DisclaimerSection
                setShowDisclaimer={setShowDisclaimer}
            />
        </ReactModal>



    </ReactModal >
}




const disclaimerPoints = [
    "I am ready to Post or Upload of Photograph or comments or other content related with the products of Sahyadri Farms Sustainable Grassroots Initiatives Limited.",
    "Once I Post or upload photograph or submit any material, Sahyadri Farms Sustainable Grassroots Initiatives Limited may use the same for its marketing purpose through Blockchain Technology (AgroTrust) Platform or any other social media platform.",
    "Further I am fully aware of the fact that the Company may at its sole discretion decide about nature of disclosure󠄀s to be made from time to time related with the content uploaded by the user or customer and I have no objection regarding the same."
];

const DisclaimerSection = ({ setShowDisclaimer }) => {
    return <div className="selfie__modal__parent">
        <div className="title__close__btns">
            <div></div>
            <div className="bold text__center" style={{ minWidth: "fit-content" }}>
                Disclaimer
            </div>
            <div className="close__btn__container">
                <button
                    id="close__btn"
                    onClick={() => setShowDisclaimer(false)}
                ></button>
            </div>
        </div>

        <ul>
            {disclaimerPoints.map(point =>
                <li style={{ marginBottom: 10 }}>{point}</li>
            )}
        </ul>
    </div>
}


const HiddenInputs = ({ imgRef, onImgChange, uploadRef }) => {


    useEffect(() => {
        imgRef.current.click()
        //eslint-disable-next-line
    }, [])

    return <>
        <input
            ref={node => imgRef.current = node}
            id="fileid"
            type="file"
            onChange={onImgChange}
            accept="image/*"
            hidden
        />

        <button
            ref={uploadRef}
            style={{ display: "none" }}
            data-toggle="modal"
            data-target="#imageUploadModal"
        ></button>
    </>
}


const UploadedImage = ({ imgUrl }) => {
    return (
        <div className="uploaded__img__container text__center">
            <img
                alt="Trace Selfie"
                src={imgUrl} style={{
                    maxWidth: "100%",
                    maxHeight: "60vh"
                }} />
        </div>
    )
}

export default SelfieSection